import * as React from "react";
import {graphql} from "gatsby";
import ModularContentTemplate from "./ModularContentTemplate";


export const LANDINGPAGE_QUERY = graphql`
query LandingpageQuery($id: String!) {
  content: contentfulLandingpage(id: {eq: $id}) {
    metatitle
    title
    brandBackgroundColor
    textKleur
    logoKleur
    mainPhoto {
      gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
      title
      
        file {
          url
          contentType
        }
    }
    contentParts {
      ... on ContentfulPagePart1KolomTekstAfbeeldingKlein {
        id
        position
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
          title
        }
        textTest {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePart2KolommenMetTekst {
        id
        title
        headText {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        textLeft {
          childMarkdownRemark {
            html
          }
        }
        textRight {
          childMarkdownRemark {
            html
          }
        }
        headTextRich {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        textLeftRich {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        textRightRich {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePartAfbeeldingVolbeeld {
        id
        title
        image {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
          title
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePart1KolomTekstMediaHalfVullend {
        id
        position
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        video {
          file {
            contentType
            url
          }
        }
        placeholder {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        
        richText {
            raw
            references {
              ... on ContentfulButton {
                contentful_id
                id
                title
                url
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              } 
            }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePartTweeMediaNaastElkaarVullend {
        id
        title
        videoLeft {
          file {
            contentType
            url
          }
        }
        videoRight {
          file {
            contentType
            url
          }
        }
        placeholderLeft {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        placeholderRight {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on ContentfulPagePartVideoVolbeeld {
        id
        title
        video {
          file {
            contentType
            url
          }
        }
        placeholder {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      
      ... on ContentfulPagePartCta {
        id
        ctaText
        ctaButtonText
        ctaButtonLink
        backgroundColor
        textColor
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      
    }
  }
}
`;

const LandingpageTemplate = ({data}) => {

    const {
        content: {
            metatitle,
            mainPhoto,
            contentParts,
            title,
            brandBackgroundColor,
            textKleur,
            logoKleur
        }
    } = data;

    return (
        <>
            <ModularContentTemplate
                metatitle={metatitle}
                mainPhoto={mainPhoto}
                contentParts={contentParts}
                title={title}
                showBreadcrumb={false}
                backgroundColor={brandBackgroundColor}
            textKleur={textKleur}
            logoKleur={logoKleur}/>
        </>
    )
};

export default LandingpageTemplate;
